.tile{
    padding: 24px;
    box-shadow: 4px 4px 20px #ccc;
    border-radius: 2px;
    background-color: #FFF;
    height: 100%;
}
.tile .head{
    align-items: center;
    justify-content: flex-start;
    color: black;
}
.tile .head p{
    text-align: center;
}
.tile .body{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(60, 60, 204);
}